@import url(./assets/fonts/font/fonts.css);
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  label {
    @apply h-6 relative inline-block;
  }

  [type="checkbox"]:not(.default-control) {
    @apply w-11 h-0 cursor-pointer inline-block;
    @apply focus:outline-0 dark:focus:outline-0;
    @apply border-0 dark:border-0;
    @apply focus:ring-offset-transparent dark:focus:ring-offset-transparent;
    @apply focus:ring-transparent dark:focus:ring-transparent;
    @apply focus-within:ring-0 dark:focus-within:ring-0;
    @apply focus:shadow-none dark:focus:shadow-none;
    
    @apply after:absolute before:absolute;
    @apply after:top-0 before:top-0;
    @apply after:block before:inline-block;
    @apply before:rounded-full after:rounded-full;

    @apply after:content-[''] after:w-5 after:h-5 after:mt-0.5 after:mr-0.5;
    @apply after:shadow-md after:duration-100;

    @apply before:content-[''] before:w-10 before:h-full;
    @apply before:shadow-[inset_0_0_#000];

    @apply after:bg-white dark:after:bg-gray-50;
    @apply before:bg-gray-300 dark:before:bg-gray-600;
    @apply before:checked:bg-[#417E65] dark:before:checked:bg-[#417E65];
    @apply checked:after:duration-300 checked:after:-translate-x-4;

    @apply disabled:after:bg-opacity-75 disabled:cursor-not-allowed;
    @apply disabled:checked:before:bg-opacity-40;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
*:focus {
  outline: none;
}
input {
  user-select: none;
  -moz-user-select: none;
  -webkit-text-select: none;
  -webkit-user-select: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: #010c0800 !important;
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent;
  filter: none;
}
.history-white {
  filter: invert(98%) sepia(46%) saturate(375%) hue-rotate(262deg) brightness(109%) contrast(100%);
}
* {
  scrollbar-color: #888;
  scrollbar-width: 10px;
  scrollbar-base-color: blue;
  scrollbar-highlight-color: blue;
  scrollbar-3dlight-color: blue;
  scrollbar-arrow-color: blue;
  scrollbar-darkshadow-color: blue;
  scrollbar-face-color: blue;
  scrollbar-track-color: blue;
}
/* width */
::scrollbar,
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::scrollbar-track,
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

/* Handle on hover */
::scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fading {
  animation: fading 1.5s linear infinite;
}
@keyframes fading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
