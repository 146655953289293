.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.result-chat a {
  position: relative;
}
.close-btn {
  position: absolute;
}
.result-chat:hover a .close-btn {
  display: block;
}

@keyframes lips {
  0% {
    border-radius: 0;
    height: 0px;
  }
  20% {
    border-radius: 10px;
    height: 10px;
    width: 10px;
    /* clip-path: circle(10%); */
  }
  40% {
    border-radius: 10px;
    height: 5px;
    width: 20px;
    /* clip-path: circle(10%); */
  }
  80% {
    border-radius: 10px;
    height: 5px;
    width: 10px;
    /* clip-path: circle(10%); */
  }
  80% {
    border-radius: 10px;
    height: 5px;
    width: 10px;
    /* clip-path: circle(10%); */
  }
  100% {
    border-width: 2px;
    border-radius: 0;
    height: 0px;
  }
}
.animating {
  animation-name: lips;
  animation-duration: 500ms;
  /* animation-direction: normal; */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate, reverse, normal;
}

input,
select {
  color: #282c34;
}

.my-tab .active-tab::after {
  content: "";
  height: 3px;
  width: 100%;
  display: block;
  margin-top: 18px;
}

pre {
  direction: ltr;
  text-align: left;
}
